let lazy;

function Main() {
    this.init();
}

Main.prototype = {
    constructor: Main,
    initPlugins: function (scope) {
        let _t = this;

        // LAZYLOAD
        lazy = new LazyLoad({
            elements_selector: '.lazy',
            callback_reveal: function (elm) {
                if (elm.tagName != 'IMG' && elm.hasAttribute('data-bg'))
                    elm.classList.add('bg-loaded');
            }
        });

        // LIBS
        // 1.tabbis.es6.js
        tabbis({
            memory: true
        });

        // 2.tnsCustom.js
        document.querySelectorAll('[data-tns]').forEach(function(elm){
            if( ! uty.hasClass(elm, 'tns-slider'))
                new tnsCustom({ ID: elm }, function(){
                    console.log('tnsCustom is initialised!!!');
                });
        });

        // 3.accordion.js
        accordion.init(document);

        // 4. micro modal
        MicroModal.init();

        // 5.marquee.js
        new Marquee('marquee1', { direction: 'rtl', speed: 0.1 });
        new Marquee('marquee0', { direction: 'rtl', speed: 0.1 });
        new Marquee('marquee2', { direction: 'rtl', speed: 0.1 });

        // SITE JS

    },
    init: function () {
        let _t = this;
        _t.initPlugins(document);
    }
};

new Main();